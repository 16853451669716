import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const AAWHALandingPage = () => (
  <PhysicianLandingPage
    physician="All About Women Health Associates"
    institution="AAWHA"
    referralCode="AAWHA"
    physicianURL="https://aawha.com/"
  />
)

export default AAWHALandingPage
